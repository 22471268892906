import React, { useState } from 'react';
import { IconButton, IconButtonProps } from '@material-ui/core';
import * as Icon from './Icon';

interface Props extends IconButtonProps {
  icon: Icon.IconComponent
}

const ActionButton: React.FC<Props> = (props) => {
  const [clicked, setClicked] = useState(false);
  const BtnIcon = props.icon;

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    props?.onClick?.(event)
    setClicked(true);
    setTimeout(() => setClicked(false), 3000);
  };

  return (
    <IconButton {...props} onClick={handleClick}>
      {clicked ? <Icon.Check /> : <BtnIcon />}
    </IconButton>
  );
}

export default ActionButton;
