import React from 'react';
import { MuiThemeProvider, StylesProvider, createGenerateClassName, createMuiTheme } from '@material-ui/core/styles';

const generateClassName = createGenerateClassName({
  seed: 'ai-widget', // classes prefix
});

const theme = createMuiTheme();

interface Props {
  children: React.ReactNode,
}

export const MuiProvider: React.FC<Props>  = ({ children }) => (
  <StylesProvider generateClassName={generateClassName}>
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  </StylesProvider>
);
