export enum Colors {
  primary='#011FBB',
  secondary='#01A6C7',
  primaryGradient='#4C69FF',
  white='#FFF',
  gray='#F9F9F9',
  lightGray='#C8CACF',
  darkBlue='#4d87ff',
  lightBlue='#f6f8ff',
}

export enum Texts {
  enterYourPrompt='Enter your prompt...',
  dialogTitle='Ask Lily',
  New='New',
  Copy='Copy',
  GoodResponse='Good Response',
  BadResponse='Bad Response',
  Regenerate='Regenerate',
}
