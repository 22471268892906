import { MessageProps } from '@chatscope/chat-ui-kit-react';
import {
  INIT_CHAT,
  SEND_INITIAL_PROMPT, SEND_INITIAL_PROMPT_SUCCESS, SEND_INITIAL_PROMPT_ERROR,
  SEND_MESSAGE, SEND_MESSAGE_STREAM_SUCCESS, SEND_MESSAGE_SUCCESS, SEND_MESSAGE_ERROR,
  REGENERATE_MESSAGE,
} from './constants';

export enum AppName {
  D2M = 'd2m',
  TM = 'tm',
}

interface BaseWidgetProps {
  appName: AppName,
  companyId: string,
  userName: string,
  token: string,
  apiPath: string,
}

export interface D2MWidgetProps extends BaseWidgetProps {
  appName: AppName.D2M,
  documentRevisionId?: string,
  attachmentIds?: string[],
}

export interface TMWidgetProps extends BaseWidgetProps {
  appName: AppName.TM,
  initialPrompt: string,
}

export type WidgetProps = D2MWidgetProps | TMWidgetProps;

export interface WidgetRef {
  triggerSummarizeAttachments: (attachmendIds: string[]) => void
}

export interface WidgetState {
  isLoading: boolean
  error?: string | null
  messages: MessageProps[]
  chatSessionId?: string | null
}

export type WidgetAction =
  | { type: typeof INIT_CHAT, payload: { initMessages?: MessageProps[] } }
  | { type: typeof SEND_INITIAL_PROMPT }
  | { type: typeof SEND_INITIAL_PROMPT_SUCCESS }
  | { type: typeof SEND_INITIAL_PROMPT_ERROR; payload: { error: string } }
  | { type: typeof SEND_MESSAGE, payload: { requestMessage: MessageProps } }
  | { type: typeof SEND_MESSAGE_STREAM_SUCCESS; payload: { responseMessage: MessageProps } }
  | { type: typeof SEND_MESSAGE_SUCCESS; payload: { responseMessage: MessageProps } }
  | { type: typeof SEND_MESSAGE_ERROR; payload: { error: string } }
  | { type: typeof REGENERATE_MESSAGE; payload: { index: number } };

interface AskEnlilBaseResponse {
  SessionUSID: string,
  status: AskEnlilResponseStatus
}

interface AskEnlilPendingResponse extends AskEnlilBaseResponse {
  status: AskEnlilResponseStatus.Pending,
}

export interface AskEnlilStreamingResponse extends AskEnlilBaseResponse {
  message_id: string,
  status: AskEnlilResponseStatus.Streaming,
  prompt_type: string,
  text: string,
}

export interface AskEnlilCompletedResponse extends Omit<AskEnlilStreamingResponse, 'status'> {
  status: AskEnlilResponseStatus.Completed,
}

export type AskEnlilResponse = AskEnlilPendingResponse | AskEnlilStreamingResponse | AskEnlilCompletedResponse;

export enum AskEnlilResponseStatus {
  Pending = 'pending',
  Streaming = 'streaming',
  Completed = 'completed',
}

export interface FetchAskEnlilOptions {
  message: string
  contextAction?: string
  messageSessionId?: string | null
}
