import React from 'react';
import { MessageList as OriginList, Message, TypingIndicator, MessageProps } from '@chatscope/chat-ui-kit-react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import useStyles from './styles';
import { getMessageId } from '../../containers/Widget/helpers';
import { Tooltip, IconButton } from '@material-ui/core';
import * as Icon from '../Icon';
import { Texts } from '../../constants';
import copy from 'copy-to-clipboard';
import ActionButton from '../ActionButton';
import { apiCall } from '../../utils';
import { WidgetProps } from '../../containers/Widget/types';

interface MessageListProps {
  messages: MessageProps[]
  isLoading?: boolean
  onMessageRegenerate: (index: number) => void
  widgetProps: WidgetProps
}

const MessageList: React.FC<MessageListProps> = ({
  messages,
  isLoading,
  onMessageRegenerate,
  widgetProps,
}) => {
  const classes = useStyles();

  const handleCopyClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    const i = Number(event.currentTarget.getAttribute('data-index'));
    const text = messages[i].model?.message;

    if (text) {
      copy(text);
    }
  }

  const sendMessageFeedback = async (event: React.MouseEvent<HTMLButtonElement>, rating: 1 | -1) => {
    const i = Number(event.currentTarget.getAttribute('data-index'));
    const messageId = getMessageId(messages[i]);
    const { appName, apiPath, token } = widgetProps;

    try {
      await apiCall({
        url: `${apiPath}/message_feedback`,
        method: 'POST',
        token,
        payload: {
          message_id: messageId,
          application_id: appName,
          rating,
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  const handleGoodFeedback: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    return sendMessageFeedback(event, 1);
  }

  const handleBadFeedback: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    return sendMessageFeedback(event, -1);
  }

  const handleRegenerateClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    const i = Number(event.currentTarget.getAttribute('data-index'));
    onMessageRegenerate(i);
  }

  return (
    <OriginList
      className={classes.list}
      typingIndicator={isLoading && <TypingIndicator className={classes.typing} />}
    >
      {messages.map((message, i) => {
        const isIncoming = message.model?.direction === 'incoming';
        const isFirst = i === 0; // initial message
        const allowActions = isIncoming && !isFirst;

        return (
          <Message
            key={getMessageId(message) ?? i}
            className={isIncoming ? classes.incomingMessage : classes.outgoingMessage}
            {...message}
          >
            {message.type === 'text' && (
              <Message.TextContent>
                <div className={classes.textContent}>
                  <Markdown remarkPlugins={[remarkGfm]}>
                    {message.model?.message ?? ''}
                  </Markdown>
                </div>
                {allowActions && (
                  <Tooltip className={classes.tooltip} title={Texts.Copy}>
                    <ActionButton
                      className={classes.messageButton}
                      data-index={i}
                      icon={Icon.Copy}
                      onClick={handleCopyClick}
                    />
                  </Tooltip>
                )}
                {allowActions && (
                  <Tooltip className={classes.tooltip} title={Texts.GoodResponse}>
                    <ActionButton
                      className={classes.messageButton}
                      data-index={i}
                      icon={Icon.ThumbUp}
                      onClick={handleGoodFeedback}
                    />
                  </Tooltip>
                )}
                {allowActions && (
                  <Tooltip className={classes.tooltip} title={Texts.BadResponse}>
                    <ActionButton
                      className={classes.messageButton}
                      data-index={i}
                      icon={Icon.ThumbDown}
                      onClick={handleBadFeedback}
                    />
                  </Tooltip>
                )}
                {allowActions && (
                  <Tooltip className={classes.tooltip} title={Texts.Regenerate}>
                    <IconButton
                      className={classes.messageButton}
                      data-index={i}
                      onClick={handleRegenerateClick}
                      disabled={isLoading}
                    >
                      <Icon.RotateRight />
                    </IconButton>
                  </Tooltip>
                )}
              </Message.TextContent>
            )}
          </Message>
        )
      })}
    </OriginList>
  );
}

export default MessageList;
