import React from 'react';
import ReactDOM from 'react-dom';
import Widget, { WidgetProps } from './containers/Widget';

export const renderWidget = (containerId: string, props: WidgetProps): void => {
  const container = document.getElementById(containerId);

  if (!container) {
    return;
  }

  return ReactDOM.render(<Widget {...props} />, container);
};

export const unmountWidget = (containerId: string): boolean => {
  const container = document.getElementById(containerId);

  if (!container) {
    return false;
  }

  return ReactDOM.unmountComponentAtNode(container);
};

export { default as Widget, AppName, WidgetProps, WidgetRef } from './containers/Widget';
