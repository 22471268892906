import { MessageProps, MessageModel } from '@chatscope/chat-ui-kit-react';
import { v4 as uuidv4 } from 'uuid';

interface CreateMessageOptions {
  id?: string
  message: string
  direction: MessageModel['direction']
  type?: MessageProps['type']
  contextAction?: string
}

export const createMessage = ({ id = uuidv4(), type = 'text', message, direction, contextAction }: CreateMessageOptions): MessageProps => ({
  type,
  model: {
    message,
    direction,
    sentTime: '1 sec ago',
    sender: 'User',
    position: 'single',
    payload: {
      id,
      contextAction,
    },
  },
})

export const getMessageId = (message: MessageProps) => (message.model?.payload as Record<string, string>)?.id;
