export const delay = async (ms: number) => await new Promise(resolve => setTimeout(resolve, ms));

interface ApiCallOptions {
  url: string,
  token: string,
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE',
  sessionId?: string | null,
  payload?: Record<string, any>
}

export const apiCall = async <T = any>(options: ApiCallOptions): Promise<T> => {
  const { url, token, method = 'GET', sessionId, payload } = options;

  try {
    const response = await fetch(url, {
      method,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
        ...sessionId && { 'SessionUSID': sessionId },
      },
      body: payload ? JSON.stringify(payload) : undefined,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};
