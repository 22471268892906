import { WidgetState } from "./types"

export const MESSAGE_FETCH_DELAY = 1500
export const MESSAGE_STREAM_DELAY = 500

export const INIT_CHAT = 'INIT_CHAT' as const
export const SEND_INITIAL_PROMPT = 'SEND_INITIAL_PROMPT' as const
export const SEND_INITIAL_PROMPT_SUCCESS = 'SEND_INITIAL_PROMPT_SUCCESS' as const
export const SEND_INITIAL_PROMPT_ERROR = 'SEND_INITIAL_PROMPT_ERROR' as const
export const SEND_MESSAGE = 'SEND_MESSAGE' as const
export const SEND_MESSAGE_STREAM_SUCCESS = 'SEND_MESSAGE_STREAM_SUCCESS' as const
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS' as const
export const SEND_MESSAGE_ERROR = 'SEND_MESSAGE_ERROR' as const
export const REGENERATE_MESSAGE = 'REGENERATE_MESSAGE' as const

export const DEFAULT_STATE: WidgetState = {
  isLoading: true,
  error: null,
  messages: [],
  chatSessionId: null,
}
